import React, { useState } from "react";

export default function ContactForm() {
	const [fullname, setFullname] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
    //   Form validation state
    const [errors, setErrors] = useState({});
    //   Setting button text on form submission
    const [buttonText, setButtonText] = useState("Send");
    // Setting success or failure messages states
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let isValidForm = handleValidation();
    
        if (isValidForm) {
            setButtonText("Sending");
            const res = await fetch("/api/v1/contact/", {
                body: JSON.stringify({
                    email: email,
                    fullname: fullname,
                    subject: subject,
                    message: message,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            });
        
            const { error } = await res.json();
            if (error) {
                console.log(error);
                setShowSuccessMessage(false);
                setShowFailureMessage(true);
                setButtonText("Send");
                return;
            }
            setShowSuccessMessage(true);
            setShowFailureMessage(false);
            // Success: Clear the HTML form and state data. 
            clearForm();
            setButtonText("Send");
        }
    };

    const handleValidation = () => {
        let tempErrors = {};
        let isValid = true;
    
        if (fullname.length <= 0) {
            tempErrors["fullname"] = true;
            isValid = false;
        }
        if (email.length <= 0) {
            tempErrors["email"] = true;
            isValid = false;
        }
        if (subject.length <= 0) {
            tempErrors["subject"] = true;
            isValid = false;
        }
        if (message.length <= 0) {
            tempErrors["message"] = true;
            isValid = false;
        }
    
        setErrors({ ...tempErrors });
        console.log("errors", errors);
        return isValid;
    };

    const clearForm = ()=>{
        (document.getElementById("contactForm") as HTMLFormElement).reset();
        setFullname("");
        setEmail("");
        setSubject("");
        setMessage("");
    }

    return (
        <> 
            <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                    <form id="contactForm" className="flex-auto p-5 lg:p-10" onSubmit={handleSubmit} >
                        <h4 className="text-2xl font-semibold">
                            Do you have any questions?
                        </h4>
                        <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                            Complete this form and we will get back to you in 1-2 business days.
                        </p>
                        <div className="relative w-full mb-3 mt-8">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="full-name"
                            >
                                Full Name
                            </label>
                            <input
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Full Name"
                                autoComplete="full_name"
                                onChange={(e) => {
                                    setFullname(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="email"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                autoComplete="email" 
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="subject"
                            >
                                Subject
                            </label>
                            <input
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Subject"
                                autoComplete="subject"
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="message"
                            >
                                Message
                            </label>
                            <textarea
                                rows={4}
                                cols={80}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Type a message..."
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                                required
                            />
                        </div>
                        <div className="text-center mt-6">
                            <button
                                className="bg-blueGray-800 text-white flex flex-row items-center active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                disabled={ buttonText==="Sending" }
                            >
                                {buttonText}
                                <svg width="24" height="24" viewBox="0 0 24 24" className="text-white ml-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.00967 5.12761H11.0097C12.1142 5.12761 13.468 5.89682 14.0335 6.8457L16.5089 11H21.0097C21.562 11 22.0097 11.4477 22.0097 12C22.0097 12.5523 21.562 13 21.0097 13H16.4138L13.9383 17.1543C13.3729 18.1032 12.0191 18.8724 10.9145 18.8724H8.91454L12.4138 13H5.42485L3.99036 15.4529H1.99036L4.00967 12L4.00967 11.967L2.00967 8.54712H4.00967L5.44417 11H12.5089L9.00967 5.12761Z" fill="currentColor" />
                                </svg>
                            </button>
                        </div>
                        {showSuccessMessage?
                            <div className="text-green-600 mt-6">
                                Thank you. Your question has been sent. 
                                <br />
                                We will respond in 1-2 business days.
                            </div>
                        :''}
                        {showFailureMessage?
                            <div className="text-red-600 mt-6">
                                There was an error while trying to send the message. Please try again later or contact us directly at info@earthnodes.net
                            </div>
                        :''}
                    </form>
                </div>
            </div>
        </>
    );
}

