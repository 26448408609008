import React, {useState} from "react";

import Image from 'next/image'
// components

import Navbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footers/Footer";
import Delegation from "../components/Modals/Delegation";
import ContactForm from "../components/ContactForm/ContactForm";

export default function Landing() {

	const [delegationModalOpen, setDelegationModalOpen] = useState(false)

	return (
		<>
			<Navbar transparent setDelegationModalOpen={setDelegationModalOpen} />
			<main>
				<div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
					
					<div
						className="absolute top-0 w-full h-full bg-center bg-cover"	
					>
						<Image
							alt="Mountains"
							src="/img/Cover-Image.png"
							layout="fill"
							objectFit="cover"
							quality={100}
						/>
						<span
							id="blackOverlay"
							className="w-full h-full absolute opacity-50 bg-black"
						></span>
					</div>
					<div className="container relative mx-auto">
						<div className="items-center flex flex-wrap">
							<div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
								<div className="pr-12">
									<div className="flex flex-row justify-center ">
										<Image 
											src="/img/brand/earth-nodes-logo_white_200.png" 
											width="600" 
											height="100"
											alt="EarthNodes.net Logo"
											priority={true}
										/>
									</div>
									
									<p className="mt-8 text-2xl text-blueGray-200">
										Whether you are in the city or the country.
										<br/> We want everyone to be connected.
									</p>

								</div>
							</div>
						</div>
					</div>
					<div
						className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
						style={{ transform: "translateZ(0)" }}
					>
						<svg
						className="absolute bottom-0 overflow-hidden"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
						>
						<polygon
							className="text-blueGray-200 fill-current"
							points="2560 0 2560 100 0 100"
						></polygon>
						</svg>
					</div>
				</div>

				<section className="pb-20 bg-blueGray-200 -mt-24">
					<div className="container mx-auto px-4">
						<div className="flex flex-wrap">
							<div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
								<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
									<div className="px-4 py-5 flex-auto">
										<div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
											<i className="fas fa-users"></i>
										</div>
										<h6 className="text-xl font-semibold">Coming Together</h6>
										<p className="mt-2 mb-4 text-blueGray-500">
											Half the world is offline. The World Mobile network is more then giving people access to the internet, 
											it's empowering them in ways many take for granted.
										</p>
									</div>
								</div>
							</div>

							<div className="w-full md:w-4/12 px-4 text-center">
								<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
									<div className="px-4 py-5 flex-auto">
										<div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
											<i className="fas fa-project-diagram"></i>
										</div>
										<h6 className="text-xl font-semibold">Earth Nodes</h6>
										<p className="mt-2 mb-4 text-blueGray-500">
											Earth Nodes are the heartbeat of the World Mobile network. While the network is being built, we are ready with reliable servers to make sure our users stay connected.
										</p>
									</div>
								</div>
							</div>

							<div className="pt-6 w-full md:w-4/12 px-4 text-center">
								<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
									<div className="px-4 py-5 flex-auto">
										<div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
											<i className="fas fa-award"></i>
										</div>
										<h6 className="text-xl font-semibold">Earn Rewards</h6>
										<p className="mt-2 mb-4 text-blueGray-500">
											The World Mobile network is nearly live. Be ready to join us and earn WMT (World Mobile Tokens). Let us grow together and successfully connecting the unconnected.
										</p>
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-wrap items-center mt-32">
							<div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
								<div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
								<i className="fas fa-signal text-xl"></i>
								</div>
								<h3 className="text-3xl mb-2 font-semibold leading-normal">
									Rebooting Telecoms
								</h3>
								<p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
									With the worlds modern technology, we're able to communicate to almost every part of the world. From astronauts on the International Space Station, to submarines at the depths of the sea.
								</p>
								<p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
									Why is half the world still unconnected?
								</p>
								<a href="https://worldmobile.io/" className="font-bold text-blueGray-700 mt-8" target="_blank" rel="noopener">
									The solution is World Mobile.
								</a>
							</div>

							<div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
								<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blueGray-700">
									<Image 
										src="/img/team.jpeg" 
										className="w-full align-middle rounded-t-lg"
										alt="people in a circle with their hands in the middle"
										width="384" 
										height="256"
									/>
									<blockquote className="relative p-8 mb-4">
										<svg
										preserveAspectRatio="none"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 583 95"
										className="absolute left-0 w-full block h-95-px -top-94-px"
										>
										<polygon
											points="-30,95 583,95 583,65"
											className="text-blueGray-700 fill-current"
										></polygon>
										</svg>
										<h4 className="text-xl font-bold text-white">
											People Power
										</h4>
										<p className="text-md font-light mt-2 text-white">
											Be a part of the network that is run by the people for the people, so anyone can tap into the telecom industry, and make the world a more connected place.
										</p>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="relative py-20">
					<div
						className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
						style={{ transform: "translateZ(0)" }}
					>
						<svg
							className="absolute bottom-0 overflow-hidden"
							xmlns="http://www.w3.org/2000/svg"
							preserveAspectRatio="none"
							version="1.1"
							viewBox="0 0 2560 100"
							x="0"
							y="0"
						>
						<polygon
							className="text-white fill-current"
							points="2560 0 2560 100 0 100"
						></polygon>
						</svg>
					</div>
					<div className="container mx-auto px-4">
						<div className="items-center flex flex-wrap">
							<div className="w-full md:w-4/12 ml-auto mr-auto px-4">
								<Image 
									src="/img/people-1920.jpg" 
									className="max-w-full rounded-lg shadow-lg"
									alt="Collage of people"
									width="384" 
									height="256"
								/>
							</div>
							<div className="w-full md:w-5/12 ml-auto mr-auto px-4">
								<div className="md:pr-12">
									<div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blueGray-200">
										<i className="fas fa-handshake text-xl"></i>
									</div>
									<h3 className="text-3xl font-semibold">Delegate and Earn rewards.</h3>
									<p className="mt-4 text-lg leading-relaxed text-blueGray-500">
										As an Earth Node operator, we secure the network and earn WMT as per the tokenomics model. Since the World Mobile network is based on the sharing economy, as the network grows so do your rewards.
									</p>
									<p className="mt-4 text-lg leading-relaxed text-blueGray-500">
										What you can do with WMT?
									</p>
									<ul className="list-none mt-6">
										<li className="py-2">
										<div className="flex items-center">
											<div>
												<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-100 mr-3">
													<i className="fas fa-fingerprint"></i>
												</span>
											</div>
											<div>
												<h4 className="text-blueGray-500">
													Secure the network by staking or delegating to an Earth Node.
												</h4>
											</div>
										</div>
										</li>
										<li className="py-2">
											<div className="flex items-center">
												<div>
													<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-100 mr-3">
														<i className="fas fa-project-diagram"></i>
													</span>
												</div>
												<div>
													<h4 className="text-blueGray-500">
														Easily transfer peer-to-peer or pay online.
													</h4>
												</div>
											</div>
										</li>
										<li className="py-2">
											<div className="flex items-center">
												<div>
													<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-100 mr-3">
														<i className="fas fa-phone"></i>
													</span>
												</div>
												<div>
													<h4 className="text-blueGray-500">
														Cover calls, texts, data usage or online services.
													</h4>
												</div>
											</div>
										</li>
										<li className="py-2">
											<div className="flex items-center">
												<div>
													<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-100 mr-3">
														<Image 
															src="/img/wm-favicon-180x180.png" 
															className=" mx-auto max-w-120-px"
															alt="World Mobile Logo"
															width="12" 
															height="12"
														/>
													</span>
												</div>
												<div>
													<h4 className="text-blueGray-500">
														Visit {" "}
														<a
															className="text-blueGray-600 hover:text-blueGray-800 font-semibold"
															href="https://worldmobiletoken.com/"
															target="_blank"
															rel="noopener"
														>World Mobile Token </a> to learn more.
													</h4>
												</div>
											</div>
										</li>

										
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="pt-20 pb-48">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap justify-center text-center mb-24">
						<div className="w-full lg:w-6/12 px-4">
							<h2 className="text-4xl font-semibold">The Experience</h2>
							<p className="text-lg leading-relaxed m-4 text-blueGray-500">
								EarthNodes.net is backed by experienced system administrators with a history in blockchain.
							</p>
						</div>
					</div>
					<div className="flex flex-wrap">
						<div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
							<div className="px-6">
								<div className="shadow-lg rounded-full mx-auto max-w-120-px flex self-center">
									<Image 
										src="/img/adaoceanaustralia_icon_500.png" 
										className=" mx-auto max-w-120-px"
										alt="ADA Ocean Australia Logo"
										width="120" 
										height="120"
									/>
								</div>
								<div className="pt-6 text-center">
									<h5 className="text-xl font-bold">ADA Ocean Australia</h5>
									<p className="mt-1 text-sm text-blueGray-600 uppercase font-semibold">
										Cardano Stake Pool 
									</p>
									<div className="mt-6">
										<a href="https://twitter.com/ADAOceanAus" target="_blank" rel="noopener">
											<button
												className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
												type="button"
											>
												<i className="fab fa-twitter"></i>
												<span className="sr-only">ADA Ocean Australia Twitter Button</span>
											</button>
										</a>
										<a href="https://adaocean.com.au" target="_blank" rel="noopener">
											<button
												className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
												type="button"
											>
												<i className="fas fa-globe"></i>
												<span className="sr-only">ADA Ocean Australia Website Button</span>
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>
						{/*
						<div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
							<div className="px-6">
								<img
									alt="..."
									src="/img/cgibson.jpg"
									className="shadow-lg rounded-full mx-auto max-w-120-px"
								/>
								<div className="pt-6 text-center">
									<h5 className="text-xl font-bold">Casey Gibson</h5>
									<p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
										Full Stake Developer
									</p>
									<div className="mt-6">
										<a href="https://twitter.com/Casey_D_Gibson" target="_blank" rel="noopener">
											<button
												className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
												type="button"
											>
												<i className="fab fa-twitter"></i>
											</button>
										</a>
										<a href="https://www.linkedin.com/in/caseydgibson" target="_blank" rel="noopener">
											<button
												className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
												type="button"
											>
												<i className="fab fa-linkedin"></i>
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>
						*/}
							<div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 px-4">
								<div className="px-6">
									<div className="shadow-lg rounded-full mx-auto max-w-120-px flex self-center">
										<Image 
											src="/img/adatools.io_520.png" 
											alt="ADATools Logo"
											width="120" 
											height="120"
										/>
									</div>
									<div className="pt-6 text-center">
										<h5 className="text-xl font-bold">ADATools.io</h5>
										<p className="mt-1 text-sm text-blueGray-600 uppercase font-semibold">
											Cardano (ADA) Blockchain Explorer
										</p>
										<div className="mt-6">
											<a href="https://twitter.com/ADATools_io" target="_blank" rel="noopener">
												<button
													className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
													type="button"
												>
													<i className="fab fa-twitter"></i>
													<span className="sr-only">ADATools Twitter Button</span>
												</button>
											</a>
											<a href="https://adatools.io" target="_blank" rel="noopener">
												<button
													className="bg-blueGray-700 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
													type="button"
												>
													<i className="fas fa-globe"></i>
													<span className="sr-only">ADATools Website Button</span>
												</button>
											</a>
										</div>
									</div>
								</div>			
							</div>
						</div>
					</div>
				</section>

				<section className="pb-20 relative block bg-blueGray-800">
				<div
					className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
					style={{ transform: "translateZ(0)" }}
				>
					<svg
						className="absolute bottom-0 overflow-hidden"
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
					>
					<polygon
						className="text-blueGray-800 fill-current"
						points="2560 0 2560 100 0 100"
					></polygon>
					</svg>
				</div>

				<div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
					<div className="flex flex-wrap text-center justify-center">
						<div className="w-full lg:w-6/12 px-4">
							<h2 className="text-4xl font-semibold text-white">
								Bridging the gap.
							</h2>
							<p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
								EarthNodes.net has a World Mobile Earth Node reservation and is ready to connect the unconnected.
							</p>
							<p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
								Be ready to start staking WMT with EarthNodes.net. Not only will you gain WMT rewards, but you will be helping change the lives of others. 
							</p>
							<p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
								By delegating to EarthNodes.net, you will:
							</p>
						</div>
					</div>
					<div className="flex flex-wrap mt-12 justify-center">
						<div className="w-full lg:w-3/12 px-4 text-center">
							<div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
							<i className="fas fa-signal text-xl"></i>
							</div>
							<h6 className="text-xl mt-5 font-semibold text-white">
								Bridge the digital divide.
							</h6>
							<p className="mt-2 mb-4 text-blueGray-400">
								Unlock access to digital banking, online education, and healthcare for billions of people worldwide, starting in Africa.
							</p>
						</div>
						<div className="w-full lg:w-3/12 px-4 text-center">
							<div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
							<i className="fas fa-chart-line text-xl"></i>
							</div>
							<h5 className="text-xl mt-5 font-semibold text-white">
								Create a sustainable business model.
							</h5>
							<p className="mt-2 mb-4 text-blueGray-400">
								Empower entrepreneurs to connect themselves and others while earning rewards.
							</p>
						</div>
						<div className="w-full lg:w-3/12 px-4 text-center">
							<div className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
								<i className="fas fa-smile text-xl"></i>
							</div>
							<h5 className="text-xl mt-5 font-semibold text-white">
								Unlock the freedom of choice.
							</h5>
							<p className="mt-2 mb-4 text-blueGray-400">
								Give people the choice to connect to the Internet, so they can earn, learn and grow.
							</p>
						</div>
					</div>
				</div>
				</section>
				<section className="relative block py-24 lg:pt-0 bg-blueGray-800">
					<div className="container mx-auto px-4">
						<div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
							<ContactForm />
						</div>
					</div>
				</section>
			</main>
			<Footer />
			<Delegation delegationModalOpen={delegationModalOpen} setDelegationModalOpen={setDelegationModalOpen} />
		</>
	);
}
