/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

export default function Delegation(props) {
    const {delegationModalOpen = false, setDelegationModalOpen, ...rest} = props;

	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [country, setCountry] = useState("");

	const cancelButtonRef = useRef(null)

	//   Form validation state
	const [errors, setErrors] = useState({});
	//   Setting button text on form submission
	const [buttonText, setButtonText] = useState("Subscribe");
	// Setting success or failure messages states
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showFailureMessage, setShowFailureMessage] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		let isValidForm = handleValidation();
	
		if (isValidForm) {
			setButtonText("Subscribing");
			const res = await fetch("/api/v1/subscribe/", {
				body: JSON.stringify({
					email: email,
					first_name: firstName,
					country: country
				}),
				headers: {
					"Content-Type": "application/json",
				},
				method: "POST",
			});
		
			const { error, ...rest } = await res.json();
			if (error) {
				setShowSuccessMessage(false);
				setShowFailureMessage(true);
				setButtonText("Subscribe");
				return;
			}
			setShowSuccessMessage(true);
			setShowFailureMessage(false);
			// Success: Clear the HTML form and state data. 
			clearForm();
			setButtonText("Subscribe");
		}
	};

	const handleValidation = () => {
		let tempErrors = {};
		let isValid = true;

		if (email.length <= 0) {
			tempErrors["email"] = true;
			isValid = false;
		}
	
		setErrors({ ...tempErrors });
		return isValid;
	};

	const clearForm = ()=>{
		(document.getElementById("subscriberForm") as HTMLFormElement).reset();
		setFirstName("");
		setEmail("");
		setCountry("");
	}

	return (
		<Transition.Root show={delegationModalOpen} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setDelegationModalOpen}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<form id="subscriberForm" className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" onSubmit={handleSubmit} >
							<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Delegations Not Active
										</Dialog.Title>
										<div className="mt-2" >
											<p className="text-sm text-gray-500 mb-3">
												Delegations to World Mobile Earth Nodes are not live yet as the network is still being rolled out and tested.
											</p>
											<p className="text-sm text-gray-500 mb-3">
												If you would like to be notified once Staking is live, fill in your email address and we will add you to our email newsletter.
											</p>
											<div className="relative w-full mb-3">
												<label
													className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
													htmlFor="email"
												>
													Email
												</label>
												<input
													type="email"
													className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="Email"
													autoComplete="email" 
													onChange={(e) => {
														setEmail(e.target.value);
													}}
													required
												/>
											</div>
											<div className="relative w-full mb-3">
												<label
													className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
													htmlFor="first_name"
												>
													First Name (optional)
												</label>
												<input
													type="text"
													className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="First Name"
													autoComplete="first_name"
													onChange={(e) => {
														setFirstName(e.target.value);
													}}
												/>
											</div>
											<div className="relative w-full mb-3">
												<label
													className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
													htmlFor="country"
												>
													Country (optional)
												</label>
												<input
													type="text"
													className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
													placeholder="Country"
													autoComplete="country"
													onChange={(e) => {
														setCountry(e.target.value);
													}}
												/>
											</div>
										</div>
										{showSuccessMessage?
										<div className="text-green-600 mt-6">
											Thank you. You have been subscribed to our newsletter. 
										</div>
												:''}
										{showFailureMessage?
											<div className="text-red-600 mt-6">
												There was an error while trying to subscribe. Please try again later or contact us directly at info@earthnodes.net
											</div>
										:''}
									</div>
								</div>
							</div>
							
							<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">		
								<button
									className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
									disabled={ buttonText==="Subscribing" }
									type="submit"
								>
									{buttonText}
								</button>	
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
									onClick={() => {
										setShowSuccessMessage(false);
										setShowFailureMessage(false);
										setErrors({});
										setButtonText("Subscribe");
										setDelegationModalOpen(false)
									}}
									ref={cancelButtonRef}
								>
									Close
								</button>
							</div>
						</form>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
