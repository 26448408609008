import React from "react";
import Link from "next/link";
// components
import Image from 'next/image'

import PagesDropdown from "../../components/Dropdowns/PagesDropdown";

export default function Navbar(props) {
	const {setDelegationModalOpen, ...rest} = props;
	const [navbarOpen, setNavbarOpen] = React.useState(false);
	return (
		<>
			<nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
				<div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
					<div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
						{/*
						<Link href="/">
							<div className="flex flex-row justify-center">
								<Image 
									src="/img/brand/earth-nodes-logo_white_200.png" 
									width="300" 
									height="50"
									alt="EarthNodes.net Logo"
									priority={true}
								/>
							</div>
						</Link>
						*/}
						<button
							className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
							type="button"
							onClick={() => setNavbarOpen(!navbarOpen)}
						>
							<i className="text-white fas fa-bars"></i>
						</button>
					</div>
					<div
						className={
							"lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
							(navbarOpen ? " block rounded shadow-lg" : " hidden")
						}
						id="example-navbar-warning"
					>
						{/*
							<ul className="flex flex-col lg:flex-row list-none mr-auto">
								<li className="flex items-center">
									<a
										className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
										href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/overview/notus?ref=nnjs-auth-navbar"
									>
									<i className="lg:text-blueGray-200 text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2" />{" "}
										Docs
									</a>
								</li>
							</ul>
						*/}
						
						<ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
							{/*
							<li className="flex items-center">
								<PagesDropdown />
							</li>
							*/}

							<li className="flex items-center">
								<a
									className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
									href="https://twitter.com/EarthNodesNet"
									target="_blank"
									rel="noopener"
								>
								<i className="lg:text-blueGray-200 text-blueGray-400 fab fa-twitter text-lg leading-lg " />
									<span className="lg:hidden inline-block ml-2">Tweet</span>
									<span className="sr-only">EarthNodes.net Twitter Button</span>
								</a>
							</li>
							
							<li className="flex items-center">
								<button
									className="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
									type="button"
									data-bs-toggle="modal" data-bs-target="#delegation-modal"
									onClick={() => setDelegationModalOpen(true)}
								>
									Delegate
								</button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
